const data = {
  user: {
    id: localStorage.getItem("userId"),
    token: localStorage.getItem("userToken"),
    name: '',
    isLogin: false,
  },
  admin: {
    id: localStorage.getItem("adminId"),
    token: localStorage.getItem("adminToken"),
    isLogin: false,
    name: '',
  },
};

export default data;
