import Vue from 'vue';
import App from './App.vue';
import Antd from 'ant-design-vue';
import router from './router';
import axios from 'axios';
import 'ant-design-vue/dist/antd.css';
import store from './common/common';
import MyComponents from './components';
import 'wowjs/css/libs/animate.css'
import 'animate.css'
import { WOW } from 'wowjs';


axios.defaults.baseURL = process.env.VUE_APP_SERVER;

//axios拦截器,默认添加header
axios.interceptors.request.use(
  (config) => {
    config.headers.adminToken = store.admin.token;
    config.headers.token = store.user.token;
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

Vue.prototype.axios = axios;
Vue.prototype.store = store;
Vue.prototype.WOW = WOW;
Vue.prototype.cdn = process.env.VUE_APP_SERVER;
Vue.prototype.serverUrl = process.env.VUE_APP_SERVER;

Vue.config.productionTip = false;
Vue.use(Antd);
Vue.use(MyComponents);

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
