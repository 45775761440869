/*
 * @Author: jarvis
 * @Date: 2023-05-01 09:08:00
 * @LastEditors: jarvis
 * @LastEditTime: 2023-05-01 20:01:34
 * @Description: 
 * @FilePath: \ydy-pc 备份\src\components\index.js
 */
const MyComponents = {
  // install 是默认的方法。当外界在 use 这个组件的时候，就会调用本身的 install 方法，同时传一个 Vue 这个类的参数。
  install: function (Vue) {
    Vue.component('HelloWorld', () => import('@/components/HelloWorld'));
    Vue.component('UploadWidget', () => import('@/components/UploadWidget'));
    Vue.component('UploadFile',() => import('@/components/UploadFile'));
  },
};
// 导出
export default MyComponents;
