/*
 * @Author: jarvis
 * @Date: 2022-03-30 17:07:03
 * @LastEditors: jarvis
 * @LastEditTime: 2022-09-01 08:52:26
 * @Description: 
 * @FilePath: \ydy-pc\src\router\home.js
 */
import Dashboard from './dashboard';

/**
 * 主网站路由
 */

const routes = [{
        path: '/load_one_news',
        name: 'loadOneNews',
        meta: { title: 'loadOneNews' },
        component: () =>
            import ('@/views/home/loadOneNews'),
    },
    // {
    //     path: '/',
    //     name: 'index',
    //     meta: { title: '首页' },
    //     component: () =>
    //         import ('@/views/home/index'),
    // },
    {
        path: '/resouce',
        name: 'resouce',
        meta: { title: '资源下载' },
        component: () =>
            import ('@/views/home/resouce'),
    },
    {
        path: '/login',
        name: 'adminLogin',
        meta: { title: '管理后台登录' },
        component: () =>
            import ('@/views/home/AdminLogin'),
    },
    {
        path: '/',
        name: 'homePage',
        meta: { title: '首页' },
        component: () =>
            import ('@/views/home/homePage'),
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        meta: { title: '管理后台' },
        component: () =>
            import ('@/views/dashboard/Dashboard'),
        children: [...Dashboard.routes],
    },
    {
        path: '/home',
        name: 'Home',
        meta: { title: '测试专用' },
        component: () =>
            import ('@/views/Home'),
    },
    {
        path: '/about',
        name: 'About',
        meta: { title: '关于我们' },
        component: () =>
            import ('@/views/About'),
    },
    {
        path: '/couldManage',
        name: 'couldManage',
        meta: { title: '云管理' },
        component: () =>
            import ('@/views/home/couldManage'),
    },
    {
        path: '/gameManage',
        name: 'gameManage',
        meta: { title: '竞赛平台' },
        component: () =>
            import ('@/views/home/gameManage'),
    },
    {
        path: '/staffPresence',
        name: 'staffPresence',
        meta: { title: '员工风采' },
        component: () =>
            import ('@/views/home/staffPresence'),
    },
    {
        path: '/joinUs',
        name: 'joinUs',
        meta: { title: '加入我们' },
        component: () =>
            import ('@/views/home/joinUs'),
    },
    {
        path: '/news_information',
        name: 'NewsInformation',
        meta: { title: '新闻资讯' },
        component: () =>
            import ('@/views/home/NewsInformation'),
    },
    {
        path: '/craftsman_workshop',
        name: 'CraftsmanWorkshop',
        meta: { title: '工匠工坊' },
        component: () =>
            import ('@/views/home/CraftsmanWorkshop'),
    },
    {
        path: '/trainingRoom',
        name: 'trainingRoom',
        meta: { title: '专业实训室' },
        component: () =>
            import ('@/views/home/trainingRoom'),
    },
    {
        path: '/resourceDevelopment',
        name: 'resourceDevelopment',
        meta: { title: '专业实训室' },
        component: () =>
            import ('@/views/home/resourceDevelopment'),
    },
    {
        path: '/trainingCertification',
        name: 'trainingCertification',
        meta: { title: '培训认证' },
        component: () =>
            import ('@/views/home/trainingCertification'),
    },
    {
        path: '/training_platform',
        name: 'TrainingPlatform',
        meta: { title: '实训平台' },
        component: () =>
            import ('@/views/trainingplatform/TrainingPlatform'),
    },
];

export default routes;