/**
 * 管理后台路由
 */

const group = [
  { name: '资讯资源', icon: 'bars' },
  { name: '运营', icon: 'solution' },
  { name: '设置', icon: 'setting' },
];

const routes = [
  {
    path: '/dashboard',
    name: 'sectionList1',
    meta: { group: '资讯资源', title: '栏目管理', hidden: true },
    component: () => import('@/views/dashboard/section/SectionList'),
  },
  {
    path: '/dashboard/section_list',
    name: 'sectionList',
    meta: { group: '资讯资源', title: '栏目管理', hidden: false },
    component: () => import('@/views/dashboard/section/SectionList'),
  },
  {
    path: '/dashboard/news_list',
    name: 'newsList',
    meta: { group: '资讯资源', title: '新闻列表', hidden: false },
    component: () => import('@/views/dashboard/news/NewsList'),
  },
  {
    path: '/dashboard/news_add',
    name: 'newsAdd',
    meta: { group: '文章管理', title: '新增文章', hidden: true },
    component: () => import('@/views/dashboard/news/NewsAdd'),
  },
  {
    path: '/dashboard/article_update',
    name: 'newsUpdate',
    meta: { group: '文章管理', title: '修改文章', hidden: true },
    component: () => import('@/views/dashboard/news/NewsUpdate'),
  },
  {
    path: '/dashboard/resource_list',
    name: 'resourceList',
    meta: { group: '资讯资源', title: '资源列表', hidden: false },
    component: () => import('@/views/dashboard/resource/Resource'),
  },
  {
    path: '/dashboard/consult',
    name: 'consultList',
    meta: { group: '运营', title: '咨询名单', hidden: false },
    component: () => import('@/views/dashboard/consult/Consult'),
  },
  {
    path: '/dashboard/banner',
    name: 'bannnertList',
    meta: { group: '运营', title: 'banner管理', hidden: false },
    component: () => import('@/views/dashboard/banner/Banner'),
  },
  {
    path: '/dashboard/experts',
    name: 'expertsList',
    meta: { group: '运营', title: '专家智库', hidden: false },
    component: () => import('@/views/dashboard/experts/Experts'),
  },

  {
    path: '/dashboard/friend_link',
    name: 'friend_link_List',
    meta: { group: '设置', title: '友情链接', hidden: false },
    component: () => import('@/views/dashboard/friend_link/FriendLink'),
  },
  {
    path: '/dashboard/user',
    name: 'user_List',
    meta: { group: '设置', title: '用户管理', hidden: false },
    component: () => import('@/views/dashboard/user/User'),
  },

  {
    path: '/dashboard/look_news',
    name: 'lookNews',
    meta: { group: '资讯资源', title: '栏目管理', hidden: true },
    component: () => import('@/views/dashboard/news/LookNews'),
  },
];

/**--------------------------------------------下面的代码不要改------------------------------------------------- */
/**把路由合并到组内 */
for (let rt of routes) {
  let gp = findGroupByName(rt.meta.group); //找到改路由对应的组
  if (gp != null) {
    //如果没有children节点就动态添加一个空节点
    if (gp.children == undefined) {
      gp.children = [];
      gp.size = 0;
    }
    //把路由放到组中
    gp.children.push(rt);
    //判断该路由是否在菜单中显示出来
    if (!rt.meta.hidden) {
      gp.size++;
      gp.baseRoute = rt;
    }
  }
}

/**根据名字找到对应的组 */
function findGroupByName(name) {
  for (let g of group) {
    if (g.name == name) {
      return g;
    }
  }
  return null;
}

export default { routes, group };
